/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDraggable } from '@dnd-kit/core';
import { Badge, styled, Tooltip, TooltipProps } from '@mui/material';
import { keyframes } from '@mui/system';
import clsx from 'clsx';
import React, { ComponentProps, RefObject } from 'react';
import { match } from 'ts-pattern';
import { Song } from '../../common/Song';
import { REPEAT_ALL, REPEAT_SINGLE } from '../constants/repeatModes';
import { MaskIcon } from '../theme/data-display/MaskIcon/MaskIcon';
import { RepeatMode } from '../types/RepeatMode';
import { DraggingData, LOCATION } from '../utils/dnd';
import { formatDurationShort } from '../utils/string-utils';
import Artwork from './Artwork';
import styles from './Player.module.scss';
import VolumeControl from './player/VolumeControl';
import SeekBar from './SeekBar';

export const ActivityBadge = styled(Badge)({
  display: 'block',
  '& .MuiBadge-colorPrimary': {
    backgroundColor: 'var(--badge-red)',
  },
});

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -5px, 0);
  }

  70% {
    transform: translate3d(0, -5px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

export const ActivityTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      classes={{
        popper: className,
      }}
    />
  )
)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'var(--badge-red)',
    fontSize: 13,
    borderRadius: 5,
    fontWeight: 600,
    minWidth: 15,
    textAlign: 'center',
    animation: `${bounce} .5s ease`,
  },
  '& .MuiTooltip-arrow': {
    color: 'var(--badge-red)',
  },
  '&.MuiTooltip-popper': {
    zIndex: 102,
  },
});

export function formatProgress(progress: number, max: number) {
  return formatDurationShort(max * progress);
}

export const repeatToVariant = {
  repeat_off: 'repeat',
  repeat_all: 'repeat',
  repeat_single: 'repeatOnce',
} as const;

export interface PlayerTemplateProps {
  activityButtonProps?: ComponentProps<'button'>;
  activityTooltipShown: boolean;
  dragRef: RefObject<HTMLDivElement>;
  durationFormatted: string;
  handleToggleDuration: () => void;
  isActivityOpen: boolean;
  isMuted: boolean;
  isQueueOpen: boolean;
  layout?: 'large' | 'mid' | 'compact';
  localDuration: number;
  localPlaybackProgress: number;
  maxiButtonProps?: ComponentProps<'button'>;
  next: () => void;
  onClickChangeRepeatMode: () => void;
  onClickContext: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickSelectSong?: () => void;
  onClickShuffle: () => void;
  onPrevious: () => void;
  onToggleMute: () => void;
  onVolumeChange: (volume: number) => void;
  pendingLength: number;
  playbackState: boolean;
  progressFormatted: string;
  queueButtonProps?: ComponentProps<'button'>;
  repeatMode: RepeatMode;
  seekTo: (time: number) => void;
  shuffle: boolean;
  skeleton?: boolean;
  song?: Song;
  songSubtitle: string;
  togglePlaybackState: () => void;
  volume: number;
}

export function PlayerTemplate({
  activityButtonProps,
  activityTooltipShown,
  dragRef,
  durationFormatted,
  handleToggleDuration,
  isActivityOpen,
  isMuted,
  isQueueOpen,
  layout = 'large',
  localDuration,
  localPlaybackProgress,
  maxiButtonProps,
  next,
  onClickChangeRepeatMode,
  onClickContext,
  onClickSelectSong,
  onClickShuffle,
  onPrevious,
  onToggleMute,
  onVolumeChange,
  pendingLength,
  playbackState,
  progressFormatted,
  queueButtonProps,
  repeatMode,
  seekTo,
  shuffle,
  skeleton,
  song,
  songSubtitle,
  togglePlaybackState,
  volume,
}: PlayerTemplateProps) {
  const { setNodeRef, attributes, listeners } = useDraggable({
    id: `${LOCATION.PLAYER} ${song?.audioId}`,
    data: song
      ? ({
          resource: [song],
          location: LOCATION.PLAYER,
        } satisfies DraggingData)
      : undefined,
    disabled: !song,
  });

  // console.log('disabled', !song, song);

  return (
    <div
      className={clsx(
        styles.mainContainer,
        skeleton && styles.skeleton,
        layout === 'compact' && styles.compact
      )}
      data-tid="container"
    >
      <div className={styles.leftSection}>
        <div ref={setNodeRef} {...attributes} {...listeners}>
          <Artwork
            backgroundColor={song ? undefined : '#1f1f1f'}
            coverImagePath={song?.coverImagePath}
            id={song?.audioId}
            onClick={onClickSelectSong}
            onContextMenu={onClickContext}
            size={50}
            skeleton={skeleton}
            title="Go To Playing File"
          />
        </div>
        <div className={styles.songInfo}>
          <div
            className={clsx(
              styles.title,
              onClickSelectSong && styles.clickable
            )}
            data-testid="player-song-title"
            draggable={Boolean(song) && Boolean(dragRef.current)}
            onClick={onClickSelectSong}
            onContextMenu={onClickContext}
            title="Go To Playing File"
            {...attributes}
            {...listeners}
          >
            {song ? song.title : ''}
          </div>
          <div
            className={clsx(
              styles.artist,
              onClickSelectSong && styles.clickable
            )}
            draggable={Boolean(song) && Boolean(dragRef.current)}
            onContextMenu={onClickContext}
            {...attributes}
            {...listeners}
          >
            {songSubtitle}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          styles.controls,
          layout === 'compact' && styles.compact
        )}
      >
        <div className={clsx(styles.buttons)}>
          {layout === 'large' && (
            <button
              className={clsx(styles.button, shuffle && styles.selected)}
              onClick={onClickShuffle}
              title="Shuffle"
              type="button"
            >
              <MaskIcon variant="shuffle" />
            </button>
          )}
          {layout !== 'compact' && (
            <button
              className={clsx(styles.button)}
              onClick={onPrevious}
              title="Previous"
              type="button"
            >
              <MaskIcon variant="previous" />
            </button>
          )}
          <button
            className={clsx(styles.button)}
            onClick={togglePlaybackState}
            title={playbackState ? 'Pause' : 'Play'}
            type="button"
          >
            <MaskIcon variant={playbackState ? 'pause' : 'play'} />
          </button>
          <button
            className={clsx(styles.button)}
            onClick={next}
            title="Next"
            type="button"
          >
            <MaskIcon variant="next" />
          </button>
          {layout === 'large' && (
            <button
              className={clsx(
                styles.button,
                (repeatMode === REPEAT_ALL || repeatMode === REPEAT_SINGLE) &&
                  styles.selected
              )}
              onClick={onClickChangeRepeatMode}
              title={match(repeatMode)
                .with('repeat_single', () => 'Repeat File')
                .otherwise(() => 'Repeat')}
              type="button"
            >
              <MaskIcon variant={repeatToVariant[repeatMode]} />
            </button>
          )}
        </div>
        {layout !== 'compact' && (
          <div className={clsx(styles.seekBar)}>
            <span className={styles.progress} onClick={handleToggleDuration}>
              <span className={styles.durationElapsed}>
                {progressFormatted}
              </span>
            </span>
            <div>
              <SeekBar
                disabled={!song || localDuration === 0}
                getTooltipValue={formatProgress}
                max={localDuration}
                onSeekComplete={seekTo}
                showTooltip
                stickyPrimaryBar
                value={localPlaybackProgress}
              />
            </div>
            <span
              className={clsx(styles.duration)}
              onClick={handleToggleDuration}
            >
              {!durationFormatted.startsWith('-') && (
                <span className={styles.negativeDuration}>-</span>
              )}
              {durationFormatted}
            </span>
          </div>
        )}
      </div>
      {layout !== 'compact' && (
        <div className={styles.rightSection}>
          {layout === 'large' && (
            <>
              <button
                {...queueButtonProps}
                className={clsx(styles.button, isQueueOpen && styles.selected)}
                title="Play Queue"
                type="button"
              >
                <MaskIcon variant="queue" />
              </button>
              <button
                {...activityButtonProps}
                className={clsx(
                  styles.button,
                  isActivityOpen && styles.selected
                )}
                title="Activity"
                type="button"
              >
                <ActivityTooltip
                  arrow
                  disableHoverListener
                  open={activityTooltipShown}
                  PopperProps={{
                    popperOptions: {
                      placement: 'top',
                    },
                  }}
                  title={pendingLength}
                >
                  <ActivityBadge
                    color="primary"
                    invisible={!pendingLength}
                    overlap="circular"
                    variant="dot"
                  >
                    <MaskIcon variant="activity" />
                    {/* <ActivityIcon /> */}
                  </ActivityBadge>
                </ActivityTooltip>
              </button>
            </>
          )}
          <VolumeControl
            onToggleMute={onToggleMute}
            onVolumeChange={onVolumeChange}
            volume={isMuted ? 0 : volume}
          />
          {layout === 'large' && (
            <button
              className={clsx(styles.button)}
              title="Maxi Player"
              type="button"
              {...maxiButtonProps}
            >
              <MaskIcon variant="expandPlayer" />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
