import { z } from 'zod';

export const CreateWebSocketTokenResponseSchema = z.object({
  token: z.string(),
  user_id: z.string(),
});

export type CreateWebSocketTokenResponse = z.infer<
  typeof CreateWebSocketTokenResponseSchema
>;
