import { z } from 'zod';

export const FolderIdSchema = z.string().brand<'FolderId'>();
export type FolderId = z.infer<typeof FolderIdSchema>;

export const PlaylistIdSchema = z.string().brand<'PlaylistId'>();
export type PlaylistId = z.infer<typeof PlaylistIdSchema>;

export const SongIdSchema = z.string().brand<'SongId'>();
export type SongId = z.infer<typeof SongIdSchema>;

export const WorkspaceIdSchema = z.string().brand<'WorkspaceId'>();
export type WorkspaceId = z.infer<typeof WorkspaceIdSchema>;

export const ProjectIdSchema = z.string().brand<'ProjectId'>();
export type ProjectId = z.infer<typeof ProjectIdSchema>;

export const AttachmentIdSchema = z.string().brand<'AttachmentId'>();
export type AttachmentId = z.infer<typeof AttachmentIdSchema>;

export const AttachmentFolderIdSchema = z
  .string()
  .brand<'AttachmentFolderId'>();
export type AttachmentFolderId = z.infer<typeof AttachmentFolderIdSchema>;

export const WorkspaceInvitationIdSchema = z
  .string()
  .brand<'workspaceInvitationId'>();
export type WorkspaceInvitationId = z.infer<typeof WorkspaceInvitationIdSchema>;

export const ProjectInvitationIdSchema = z
  .string()
  .brand<'ProjectInvitationId'>();
export type ProjectInvitationId = z.infer<typeof ProjectInvitationIdSchema>;

export const CommentIdSchema = z.string().brand<'CommentId'>();
export type CommentId = z.infer<typeof CommentIdSchema>;

export const ResourceIdSchema = z.string().brand<'ResourceId'>();
export type ResourceId = z.infer<typeof ResourceIdSchema>;

export const DeviceIdSchema = z.string().brand<'DeviceId'>();
export type DeviceId = z.infer<typeof DeviceIdSchema>;

export const UserIdSchema = z.string().brand<'UserId'>();
export type UserId = z.infer<typeof UserIdSchema>;
