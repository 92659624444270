import { z } from 'zod';
import { SongIdSchema } from './Opaques';

export const PresignedRequestSchema = z.object({
  url: z.string(),
  fields: z.record(z.string()),
});

export type PresignedRequest = z.infer<typeof PresignedRequestSchema>;

export const PrepareUploadResponseSchema = z.object({
  audio_id: SongIdSchema,
  presigned_request: PresignedRequestSchema,
});

export type PrepareUploadResponse = z.infer<typeof PrepareUploadResponseSchema>;
