import { Command, Context, multiselect } from 'macos-multi-select';
import {
  ComponentProps,
  createContext,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useStore } from 'react-redux';
import { LOCATION_CHANGE } from 'redux-first-history';
import { SongId } from '../../../common/Opaques';
import { Song } from '../../../common/Song';
import { transformEmptyArray } from '../../../common/zod-utils';
import { INVALIDATE_VISIBLE_SONGS } from '../../actions/global';
import { navigatePanes } from '../../actions/panes';
import { selectOnlyOneSong } from '../../actions/songs/selectOnlyOneSong';
import { sortFileList } from '../../actions/songs/sortFileList';
import { setSearchFocused } from '../../actions/view';
import { navigateWorkspace } from '../../actions/workspace';
import {
  addAppListener,
  ControlStateType,
  Dispatch,
  useAppSelector,
} from '../../reducers/types';
import { memptyContext } from '../../utils/song-utils';
import { api } from '../api/api';

export const MultiSelectContext = createContext<{
  context: Context<SongId>;
  files: Song[];
  setContext: React.Dispatch<SetStateAction<Context<SongId>>>;
}>(null!);

export function MultiSelectProvider({ children }: PropsWithChildren) {
  // NOTE: cannot add the listeners to the start because the context is not yet set

  const [context, setContext] = useState(memptyContext);
  const store = useStore();

  const workspaceSongs = useAppSelector((state) =>
    transformEmptyArray(
      api.endpoints.getWorkspaceSongs.select(
        state.workspace.selectedWorkspaceId!
      )(state).data
    )
  );

  const handleMerge = useCallback(
    (state: ControlStateType) => {
      setContext((prev) =>
        multiselect<SongId>(prev, {
          type: 'MERGE INDEX',
          index: state.songs.visibleSongIds.filter(
            (id) =>
              !(
                state.uploads.songs[id]?.state != null ||
                workspaceSongs.find((s) => s.audioId === id)?.transcoding
              )
          ),
        })
      );
    },
    [workspaceSongs]
  );

  useEffect(() => {
    handleMerge(store.getState() as ControlStateType);
    const clean: (() => void)[] = [];

    clean.push(
      store.dispatch(
        addAppListener({
          predicate: (action) =>
            [
              INVALIDATE_VISIBLE_SONGS,
              sortFileList.type,
              LOCATION_CHANGE,
              navigateWorkspace.fulfilled.type,
            ].includes(action.type),
          effect: (_action, { getState }) => {
            handleMerge(getState());
          },
        })
      ) as any as () => void
    );

    clean.push(
      store.dispatch(
        addAppListener({
          type: INVALIDATE_VISIBLE_SONGS,
          effect: (_action, { getState }) => {
            const state = getState();
            const { queuedTrackSelection } = state.songs;
            if (queuedTrackSelection) {
              setContext((prev) =>
                multiselect(prev, {
                  type: 'SELECT ONE',
                  id: queuedTrackSelection,
                })
              );
            }
          },
        })
      ) as any as () => void
    );

    clean.push(
      store.dispatch(
        addAppListener({
          type: LOCATION_CHANGE,
          effect: () => {
            setContext((prev) =>
              multiselect(prev, {
                type: 'DESELECT ALL',
              })
            );
          },
        })
      ) as any as () => void
    );

    clean.push(
      store.dispatch(
        addAppListener({
          actionCreator: selectOnlyOneSong,
          effect: (action) => {
            setContext((prev) =>
              multiselect(
                multiselect(prev, {
                  type: 'DESELECT ALL',
                }),
                {
                  type: 'SELECT ONE',
                  id: action.payload,
                }
              )
            );
          },
        })
      ) as any as () => void
    );

    return () => clean.forEach((fn) => fn());
  }, [handleMerge, store]);

  useEffect(() => {
    const clean: (() => void)[] = [];
    clean.push(
      store.dispatch(
        addAppListener({
          actionCreator: navigateWorkspace.fulfilled,
          effect: () => {
            (store.dispatch as Dispatch)(navigatePanes(context));
          },
        })
      ) as any as () => void
    );

    return () => clean.forEach((fn) => fn());
  }, [context, store]);

  const files = useMemo(() => {
    return workspaceSongs.filter((file) =>
      context.selected.includes(file.audioId)
    );
  }, [workspaceSongs, context.selected]);

  return (
    <MultiSelectContext.Provider value={{ context, setContext, files }}>
      {children}
    </MultiSelectContext.Provider>
  );
}

export function useMultiSelect() {
  return useContext(MultiSelectContext);
}

export function useSetMultiSelect() {
  const { setContext } = useContext(MultiSelectContext);
  const store = useStore();
  return useCallback(
    (action: Command<SongId>) => {
      setContext((prev) => {
        const next = multiselect(prev, action);
        const state = store.getState() as ControlStateType;
        if (state.view.searchFocused) {
          store.dispatch(setSearchFocused(false));
        }
        (store.dispatch as Dispatch)(navigatePanes(next));
        return next;
      });
    },
    [setContext, store]
  );
}

export type MultiSelectItemProps = PropsWithChildren<{
  id: SongId;
}> &
  ComponentProps<'div'>;

export function MultiSelectItem({
  id,
  children,
  ...props
}: MultiSelectItemProps) {
  const multiSelect = useSetMultiSelect();
  const multiSelectItemOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      multiSelect({ type: 'SELECT ONE', id });
    },
    [id, multiSelect]
  );

  const multiSelectItemOnClickCapture = useCallback(
    (event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      if (event.metaKey || event.ctrlKey) {
        event.stopPropagation();
        event.preventDefault();
        multiSelect({ type: 'TOGGLE SELECTION', id });
      } else if (event.shiftKey) {
        event.stopPropagation();
        event.preventDefault();
        multiSelect({ type: 'SELECT ADJACENT', id });
      }
    },
    [id, multiSelect]
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      onClick={multiSelectItemOnClick}
      onClickCapture={multiSelectItemOnClickCapture}
      style={{
        position: 'relative',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
}
