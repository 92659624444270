import { z } from 'zod';
import { DeviceIdSchema, PlaylistIdSchema } from '../../common/Opaques';

const EncodedArraySchema = z
  .string()
  .transform((arg, ctx): string[] => {
    try {
      // the store cannot store arrays, so encoding/decoding is needed
      const parsed = JSON.parse(arg);
      if (!Array.isArray(parsed)) {
        throw Error('Invalid Array');
      }
      return parsed;
    } catch (e) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: (e as any)?.message ?? 'Invalid Array',
      });
      return z.NEVER;
    }
  })
  .catch(() => {
    return [];
  });

export const UserStoreElectronDownloadedSchema = z.object({
  deviceId: DeviceIdSchema,
  playlistIds: z.array(PlaylistIdSchema),
  updatedAt: z.coerce.date(),
});

export type UserStoreElectronDownloaded = z.infer<
  typeof UserStoreElectronDownloadedSchema
>;

export const UserStoreSchema = z
  .discriminatedUnion('k', [
    z.object({
      k: z.literal('electron-pinned'),
      v: EncodedArraySchema.pipe(z.array(PlaylistIdSchema)),
      created_at: z.string(),
    }),
    z.object({
      k: z.literal('electron-downloaded'),
      v: EncodedArraySchema.pipe(z.array(UserStoreElectronDownloadedSchema)),
      created_at: z.string(),
    }),
  ])
  .array()
  .transform((arg) => ({
    'electron-pinned': arg.find((_) => _.k === 'electron-pinned')?.v ?? [],
    'electron-downloaded':
      arg.find((_) => _.k === 'electron-downloaded')?.v ?? [],
  }));

export type UserStore = z.infer<typeof UserStoreSchema>;

export const UserStoreResponseSchema = z.object({
  records: UserStoreSchema,
  size: z.number(),
});

export type UserStoreResponse = z.infer<typeof UserStoreResponseSchema>;

export const defaultUserStore = {
  'electron-pinned': [],
  'electron-downloaded': [],
};
