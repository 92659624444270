/* eslint-disable jsx-a11y/media-has-caption */
import { useInterpret } from '@xstate/react';
import clsx from 'clsx';
import { constant } from 'fp-ts/lib/function';
import { motion } from 'framer-motion';
import { noop } from 'lodash';
import { createRef, useCallback, useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  PlayerTemplate,
  PlayerTemplateProps,
} from '../renderer/components/PlayerTemplate';
import { usePlayer } from '../renderer/hooks/usePlayer';
import { playerMachine } from '../renderer/machines/Player';
import { API_HOST } from '../renderer/services/apiHost';
import styles from './Player.module.scss';
import {
  addAppListener,
  playNext,
  playPrevious,
  playerSlice,
  songEnded,
  togglePlayback,
  useAppDispatch,
  useAppSelector,
} from './api';
import { SharedLink, songFromAudio } from './types';

const dragRef = createRef<HTMLDivElement>();
export interface PlayerProps {
  layout: PlayerTemplateProps['layout'];
  shareId: string;
  sharedLink: SharedLink;
}

export function Player({ sharedLink, shareId, layout }: PlayerProps) {
  const playerState = useAppSelector((state) => state.player);
  const dispatch = useAppDispatch();
  const player = useInterpret(playerMachine);

  useHotkeys(
    'down, right',
    () => {
      dispatch(playNext([shareId, sharedLink.password]));
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    'up, left',
    () => {
      dispatch(playPrevious([shareId, sharedLink.password]));
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    'space',
    () => {
      dispatch(togglePlayback([shareId, sharedLink.password]));
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    ['meta+shift+down', 'control+shift+down'],
    () => {
      dispatch(playerSlice.actions.mute());
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    'alt+down',
    () => {
      dispatch(playerSlice.actions.volumeDown());
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    'alt+up',
    () => {
      dispatch(playerSlice.actions.volumeUp());
    },
    {
      preventDefault: true,
    },
    []
  );

  useHotkeys(
    ['meta+shift+up', 'control+shift+up'],
    () => {
      dispatch(playerSlice.actions.volumeMax());
    },
    {
      preventDefault: true,
    },
    []
  );

  const song = useMemo(() => {
    return playerState.selectedAudio
      ? songFromAudio(playerState.selectedAudio)
      : undefined;
  }, [playerState.selectedAudio]);

  const onSeek = useCallback(
    (time: number, cb: (progress: number) => void) => {
      player.send({
        type: 'SOUGHT',
        payload: {
          time,
          cb,
        },
      });
    },
    [player]
  );

  const onNext = useCallback(() => {
    dispatch(playNext([shareId, sharedLink.password]));
  }, [dispatch, shareId, sharedLink.password]);

  const onSongEnded = useCallback(() => {
    dispatch(songEnded([shareId, sharedLink.password]));
  }, [dispatch, shareId, sharedLink.password]);

  const {
    audioContextRef,
    audioRef,
    durationFormatted,
    gainNodeRef,
    handleToggleDuration,
    localPlaybackProgress,
    playerVolumeRef,
    progressFormatted,
    setAudioRef,
    setProgress,
    songSubtitle,
    seekTo,
  } = usePlayer(
    song,
    undefined,
    onSongEnded,
    playerState.muted,
    playerState.volume,
    undefined,
    onSeek
  );

  useEffect(() => {
    player.send({
      type: 'INIT',
      payload: {
        audioRef,
        gainNodeRef,
        audioContextRef,
        playerVolumeRef,
        maxRetries: 3,
        onError: constant(setProgress(0)),
        onHealthy: noop,
        onProgress: setProgress,
      },
    });

    return () => {
      player.send({ type: 'DESTROY' });
    };
  }, [
    audioContextRef,
    audioRef,
    gainNodeRef,
    player,
    playerVolumeRef,
    setProgress,
  ]);

  useEffect(() => {
    const unsubscribe1 = dispatch(
      addAppListener({
        actionCreator: playerSlice.actions.mount,
        effect: (action) => {
          const audio = action.payload;
          const path = `${API_HOST}web/audio/${audio.audio_id}/m3u8?access_token=${shareId}&scope=${sharedLink.scope}`;

          player.send({
            payload: {
              authToken: '',
              autoPlay: true,
              playingSong: {
                path,
              },
              progress: 0,
              offlineSong: undefined,
            },
            type: 'MOUNTED',
          });
        },
      })
    );

    const unsubscribe2 = dispatch(
      addAppListener({
        actionCreator: playerSlice.actions.togglePlayback,
        effect: () => {
          player.send({
            type: 'PLAYBACK_TOGGLED',
          });
        },
      })
    );

    const unsubscribe3 = dispatch(
      addAppListener({
        actionCreator: playerSlice.actions.finishedPlaylist,
        effect: (action) => {
          const audio = action.payload;
          const path = `${API_HOST}web/audio/${audio.audio_id}/m3u8?access_token=${shareId}&scope=${sharedLink.scope}`;

          player.send({
            payload: {
              authToken: '',
              autoPlay: false,
              playingSong: {
                path,
              },
              progress: 0,
              offlineSong: undefined,
            },
            type: 'MOUNTED',
          });
        },
      })
    );

    return () => {
      unsubscribe1();
      unsubscribe2();
      unsubscribe3();
    };
  }, [dispatch, player, shareId, sharedLink.scope]);

  return (
    <>
      <audio ref={setAudioRef} autoPlay={false} />
      {song && (
        <motion.div
          animate={{
            opacity: 1,
          }}
          className={clsx(
            styles.player,
            layout === 'compact' && styles.compact
          )}
          initial={{
            opacity: 0,
          }}
        >
          <PlayerTemplate
            activityTooltipShown={false}
            dragRef={dragRef}
            durationFormatted={durationFormatted}
            handleToggleDuration={handleToggleDuration}
            isActivityOpen={false}
            isMuted={playerState.muted}
            isQueueOpen={false}
            layout={layout}
            localDuration={playerState.selectedAudio?.duration || 0}
            localPlaybackProgress={localPlaybackProgress}
            next={onNext}
            onClickChangeRepeatMode={noop}
            onClickContext={noop}
            onClickShuffle={noop}
            onPrevious={() => {
              dispatch(playPrevious([shareId, sharedLink.password]));
            }}
            onToggleMute={() => {
              dispatch(playerSlice.actions.toggleMute());
            }}
            onVolumeChange={(volume) => {
              dispatch(playerSlice.actions.setVolume(volume));
            }}
            pendingLength={0}
            playbackState={playerState.isPlaying}
            progressFormatted={progressFormatted}
            repeatMode="repeat_off"
            seekTo={seekTo}
            shuffle={false}
            song={song}
            songSubtitle={songSubtitle}
            togglePlaybackState={() => {
              dispatch(togglePlayback([shareId, sharedLink.password]));
            }}
            volume={playerState.volume}
          />
        </motion.div>
      )}
    </>
  );
}
