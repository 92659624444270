import {
  Dispatch,
  SnackType,
  Snackbar,
  ViewStateType,
} from '../../reducers/types';
import { WorkspaceSubscriptionEnabledFeature } from '../../services/api/types/Workspace';
import {
  BACK_HISTORY_POSITION,
  CLOSE_DIALOG,
  CLOSE_SNACKBAR,
  CLOSE_TUTORIAL_DIALOG,
  DEBUG_TOGGLE_TUTORIAL_DIALOG,
  DEBUG_TOGGLE_UPDATE_BUBBLE_SHOWN,
  ENQUEUE_SNACKBAR,
  FORWARD_HISTORY_POSITION,
  HIDE_PLANS_DIALOG,
  HIDE_UPSELL_DIALOG,
  REMOVE_SNACKBAR,
  SET_MENU_SHOWN,
  SET_MODAL,
  SET_SEARCH_FOCUSED,
  SET_TUTORIAL_STEP,
  SET_UPDATE_BUBBLE_SHOWN,
  SHOW_PLANS_DIALOG,
  SHOW_STATUS_BAR,
  SHOW_TUTORIAL_DIALOG,
  SHOW_UPSELL_DIALOG,
  TOGGLE_FEEDBACK_DIALOG,
} from './Actions';

export function showTutorialDialog() {
  return {
    type: SHOW_TUTORIAL_DIALOG,
  };
}

export function debugToggleTutorialDialog() {
  return {
    type: DEBUG_TOGGLE_TUTORIAL_DIALOG,
  };
}

export function closeTutorialDialog() {
  return {
    type: CLOSE_TUTORIAL_DIALOG,
  };
}

export function showUpsellDialog(
  feature?: WorkspaceSubscriptionEnabledFeature
) {
  return {
    type: SHOW_UPSELL_DIALOG,
    feature,
  };
}

export function hideUpsellDialog() {
  return {
    type: HIDE_UPSELL_DIALOG,
  };
}

export function showPlansDialog(isDirectUpgrade: boolean) {
  return {
    type: SHOW_PLANS_DIALOG,
    isDirectUpgrade,
  };
}

export function hidePlansDialog() {
  return {
    type: HIDE_PLANS_DIALOG,
  };
}

export function toggleFeedbackDialog() {
  return {
    type: TOGGLE_FEEDBACK_DIALOG,
  };
}

export function enqueueSnackbar(
  message: string | React.ReactNode,
  type = SnackType.SUCCESS,
  goTo?: string
) {
  const notification: Snackbar = {
    key: (new Date().getTime() + Math.random()).toString(),
    message,
    type,
    goTo,
  };
  return (dispatch: Dispatch) => {
    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification,
    });
  };
}

export function dismissSnackbar(key: string) {
  return {
    type: CLOSE_SNACKBAR,
    key,
  };
}

export function removeSnackbar(key: string) {
  return {
    type: REMOVE_SNACKBAR,
    key,
  };
}

export function setSearchFocused(focused: boolean) {
  return {
    type: SET_SEARCH_FOCUSED,
    focused,
  };
}

export function setTutorialStep(step?: number) {
  return {
    type: SET_TUTORIAL_STEP,
    step,
  };
}

export function setMenuShown(shown: boolean) {
  return {
    type: SET_MENU_SHOWN,
    shown,
  };
}

export function backHistoryPosition() {
  return {
    type: BACK_HISTORY_POSITION,
  };
}

export function forwardHistoryPosition() {
  return {
    type: FORWARD_HISTORY_POSITION,
  };
}

export function setUpdateBubbleShown(shown: boolean) {
  return {
    type: SET_UPDATE_BUBBLE_SHOWN,
    shown,
  };
}

export function debugToggleUpdateBubbleShown() {
  return {
    type: DEBUG_TOGGLE_UPDATE_BUBBLE_SHOWN,
  };
}

export function showStatusBar(shown: boolean) {
  return {
    type: SHOW_STATUS_BAR,
    shown,
  };
}

export function setModal(payload: ViewStateType['modal']) {
  return {
    type: SET_MODAL,
    payload,
  };
}

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}
