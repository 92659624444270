export enum Action {
  CHANGE_PROJECT = 'WORKSPACE/CHANGE_PROJECT',
  CHANGE_WORKSPACE = 'WORKSPACE/CHANGE_WORKSPACE',
  CREATE_PROJECT = 'WORKSPACE/CREATE_PROJECT',
  DEBUG_TOGGLE_FETCH_WORKSPACES = 'WORKSPACE/DEBUG_TOGGLE_FETCH_WORKSPACES',
  DELETE_PROJECT = 'WORKSPACE/DELETE_PROJECT',
  EDIT_WORKSPACE = 'WORKSPACE/EDIT_WORKSPACE',
  FETCH_WORKSPACES = 'WORKSPACE/FETCH_WORKSPACES',
  FETCH_WORKSPACE_SUBSCRIPTION = 'WORKSPACE/FETCH_WORKSPACE_SUBSCRIPTION',
  INVITE_MEMBER_TO_WORKSPACE = 'WORKSPACE/INVITE_MEMBER_TO_WORKSPACE',
  NAVIGATE_WORKSPACE = 'WORKSPACE/NAVIGATE_WORKSPACE',
  REINVITE_TO_PROJECT = 'WORKSPACE/REINVITE_TO_PROJECT',
  REMOVE_INVITATION_TO_PROJECT = 'WORKSPACE/REMOVE_INVITATION_TO_PROJECT',
  REMOVE_MEMBER_FROM_WORKSPACE = 'WORKSPACE/REMOVE_MEMBER_FROM_WORKSPACE',
  REMOVE_MEMBER_FROM_PROJECT = 'WORKSPACE/REMOVE_MEMBER_FROM_PROJECT',
  REMOVE_WORKSPACE_INVITATION = 'WORKSPACE/REMOVE_WORKSPACE_INVITATION',
  UPDATE_WORKSPACE_INVITATION_ROLE = 'WORKSPACE/UPDATE_WORKSPACE_INVITATION_ROLE',
  UPDATE_WORKSPACE_MEMBER_ROLE = 'WORKSPACE/UPDATE_WORKSPACE_MEMBER_ROLE',
  WORKSPACE_CHANGED = 'WORKSPACE/WORKSPACE_CHANGED',
}
