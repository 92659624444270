export const PLAY_SONG = 'PLAY_SONG';
export const UPDATE_SONG_ARTWORK_UPLOAD_STATE =
  'UPDATE_SONG_ARTWORK_UPLOAD_STATE';
export const UPLOAD_SONG = 'UPLOAD_SONG';
export const UPDATE_SONG_UPLOAD_STATE = 'UPDATE_SONG_UPLOAD_STATE';
export const ADD_SONG_TO_UPLOAD_QUEUE = 'ADD_SONG_TO_UPLOAD_QUEUE';
export const REMOVE_UPLOADING_SONG = 'REMOVE_UPLOADING_SONG';
export const ADD_SONG_WITH_ERRORS = 'ADD_SONG_WITH_ERRORS';
export const REMOVE_SONG_WITH_ERRORS = 'REMOVE_SONG_WITH_ERRORS';
export const MULTI_SELECT = 'MULTI_SELECT';
export const CENTER_SONG_SELECTION = 'CENTER_SONG_SELECTION';
export const QUEUE_SONGS_SELECTION = 'QUEUE_SONGS_SELECTION';
export const SORT_SONG_FILE_LIST = 'SORT_SONG_FILE_LIST';
export const SONG_LIMIT = 200;
