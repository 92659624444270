import { createAction } from '@reduxjs/toolkit';
import { chain, fromNullable, map } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import { Context } from 'macos-multi-select';
import { SongId } from '../../common/Opaques';
import {
  PanesType,
  RightPaneComponent,
  createAppAsyncThunk,
} from '../reducers/types';
import {
  isPlaylistPane,
  isSongPane,
  isWorkspacePane,
} from '../utils/pane-utils';
import { matchPossiblePaths } from '../utils/route-utils';

export enum PaneAction {
  ADD_LEFT_EXPANDED_PROJECTS = 'PANE/SET_LEFT_EXPANDED_PROJECTS',
  DEBUG_TOGGLE_PANES_VISIBILITY = 'PANE/DEBUG_TOGGLE_PANES_VISIBILITY',
  SET_COMPONENT = 'PANE/SET_COMPONENT',
  SET_LEFT_PANE_IS_LIBRARY_OPEN = 'PANE/SET_LEFT_PANE_IS_LIBRARY_OPEN',
  SET_LEFT_PANE_WIDTH = 'PANE/SET_LEFT_PANE_WIDTH',
  SET_LEFT_STATS_STATE = 'PANE/SET_LEFT_STATS_STATE',
  SET_TREE_EXPANDED = 'PANE/SET_TREE_EXPANDED',
  SET_WINDOW_SIZE = 'PANE/SET_WINDOW_SIZE',
  TOGGLE_DEFAULT_ACCORDION = 'PANE/TOGGLE_DEFAULT_ACCORDION',
  TOGGLE_RIGHT_PANE = 'PANE/TOGGLE_RIGHT_PANE',
}

export const setLeftPaneWidth = createAction<
  PanesType['left']['leftPaneWidth'],
  PaneAction.SET_LEFT_PANE_WIDTH
>(PaneAction.SET_LEFT_PANE_WIDTH);

export const setLeftPaneStatsState = createAction<
  PanesType['left']['statsState'],
  PaneAction.SET_LEFT_STATS_STATE
>(PaneAction.SET_LEFT_STATS_STATE);

export const setWindowSize = createAction<
  PanesType['windowSize'],
  PaneAction.SET_WINDOW_SIZE
>(PaneAction.SET_WINDOW_SIZE);

export const debugTogglePanesVisibility = createAction<
  void,
  PaneAction.DEBUG_TOGGLE_PANES_VISIBILITY
>(PaneAction.DEBUG_TOGGLE_PANES_VISIBILITY);

export const toggleRightPane = createAction<void, PaneAction.TOGGLE_RIGHT_PANE>(
  PaneAction.TOGGLE_RIGHT_PANE
);

export const toggleAccordion = createAction<
  PanesType['right']['defaultAccordionOpen'][number],
  PaneAction.TOGGLE_DEFAULT_ACCORDION
>(PaneAction.TOGGLE_DEFAULT_ACCORDION);

export const setComponent = createAction<
  PanesType['right']['component'],
  PaneAction.SET_COMPONENT
>(PaneAction.SET_COMPONENT);

export const setIsLibraryOpen = createAction(
  PaneAction.SET_LEFT_PANE_IS_LIBRARY_OPEN
);

export const setTreeExpanded = createAction<string[]>(
  PaneAction.SET_TREE_EXPANDED
);

// export const toggleFavorite = createAppAsyncThunk<void, PlaylistId>(
//   'toggleFavorites',
//   async (playlistId, { dispatch, getState }) => {
//     // the user store is prone to overriding other changes, so we need to read before writing.
//     // or maybe save the information in a CRDT later.
//     const visibleFavorites = userStoreFavoritesSelector(getState());
//     await dispatch(
//       api.endpoints.getStore.initiate(undefined, { forceRefetch: true })
//     );

//     const serverFavorites = userStoreFavoritesSelector(getState());

//     const next = visibleFavorites.includes(playlistId)
//       ? serverFavorites.filter((favorite) => favorite !== playlistId)
//       : union(serverFavorites, [playlistId]);

//     await dispatch(
//       api.endpoints.setStore.initiate({
//         'electron-pinned': next,
//       })
//     );
//   }
// );

// TODO: maybe move to multiselect listener to avoid dispatching this action
export const navigatePanes = createAppAsyncThunk(
  'navigatePanes',
  async (selection: Context<SongId>, { dispatch, getState }) => {
    const state = getState();
    pipe(
      fromNullable(state.router.location),
      chain(matchPossiblePaths),
      map((matchResult) => {
        switch (matchResult.type) {
          case 'all files':
          case 'home':
          case 'starred':
          case 'workspace':
            if (
              selection.selected.length === 0 &&
              !isWorkspacePane(state.panes.right.component)
            ) {
              dispatch(setComponent(RightPaneComponent.WORKSPACE_INFO));
            } else if (
              selection.selected.length &&
              !isSongPane(state.panes.right.component)
            ) {
              dispatch(setComponent(RightPaneComponent.FILE_INFO));
            }
            break;
          case 'playlist':
            if (
              selection.selected.length === 0 &&
              !isPlaylistPane(state.panes.right.component)
            ) {
              dispatch(setComponent(RightPaneComponent.PLAYLIST_INFO));
            } else if (
              selection.selected.length &&
              !isSongPane(state.panes.right.component)
            ) {
              dispatch(setComponent(RightPaneComponent.FILE_INFO));
            }
            break;
          default:
            break;
        }
      })
    );
  }
);
