import { z } from 'zod';
import {
  ProjectIdSchema,
  ResourceIdSchema,
  WorkspaceId,
  WorkspaceIdSchema,
} from '../../../../common/Opaques';
import { PermissionSchema } from '../../../../common/Permission';
import {
  transformEmptyArray,
  transformToDate,
  transformToNull,
} from '../../../../common/zod-utils';
import { ProjectInvitationSchema, ProjectMemberSchema } from './ProjectMember';
import {
  WorkspaceInvitationSchema,
  WorkspaceMemberSchema,
} from './WorkspaceMember';

export const WorkspaceSubscriptionEnabledFeatureSchema = z
  .enum([
    'ASSET_CONTAINER',
    'AUDIO_SHARE_LINK',
    'AUDIO_SHARE_LINK_WITH_DOWNLOADS',
    'AUDIO_SHARE_LINK_WITH_EXPIRATION',
    'AUDIO_SHARE_LINK_WITH_PASSWORD',
    'CUSTOM_STORAGE_PLANS',
    'HD_PLAYBACK',
    'REPLACE_FILE',
    'SHARE_LINK',
    'SHARE_LINK_WITH_DOWNLOADS',
    'SHARE_LINK_WITH_EXPIRATION',
    'SHARE_LINK_WITH_PASSWORD',
    'TEAM_WORKSPACE',
    'TIMESTAMPED_COMMENT',
    'WORKSPACE_BRANDING',
    'SSO',
  ])
  .catch(
    (ctx) =>
      // prevent failing when new permissions are added
      ctx.input
  );

export type WorkspaceSubscriptionEnabledFeature = z.infer<
  typeof WorkspaceSubscriptionEnabledFeatureSchema
>;

export const WorkspaceInfoStorageSchema = z
  .object({
    duration_size: z.number().nullable(),
    duration_size_hrs: z.number(),
    duration_remaining_mts: z.number(),
    segment_size: z.string().nullable(),
    segment_size_gb: z.number(),
    segment_size_limit: z.number().nullish(),
  })
  .transform((prev) => ({
    durationRemainingMts: prev.duration_remaining_mts,
    durationSize: prev.duration_size,
    durationSizeHrs: prev.duration_size_hrs,
    segmentSize: prev.segment_size,
    segmentSizeGb: prev.segment_size_gb,
    segmentSizeLimit: prev.segment_size_limit,
  }));

export const WorkspaceInfoSchema = z
  .object({
    seats_limit: z.number(),
    seats_used: z.number(),
    storage: WorkspaceInfoStorageSchema,
  })
  .transform((prev) => ({
    seatsLimit: prev.seats_limit,
    seatsUsed: prev.seats_used,
    storage: prev.storage,
  }));

export const WorkspaceSchema = z
  .object({
    string_id: WorkspaceIdSchema,
    title: z.string(),
    created_at: z.string().transform(transformToDate),
    deleted_at: z.null(),
    image_url: z.string().nullable(),
    image_orig_url: z.string().nullable(),
    description: z.string().nullable(),
    subscription: z.object({
      // light version of subscription
      plan_title: z.string().nullish().transform(transformToNull),
      plan_description: z.string().nullish().transform(transformToNull),
      enabled_feature_list: WorkspaceSubscriptionEnabledFeatureSchema.array()
        .nullish()
        .transform(transformEmptyArray),
      plan_is_free: z.boolean(),
      is_trial: z.coerce.boolean().optional(),
      plan_id: z.string().optional(),
      next_billing_at: z.string().transform(transformToDate).optional(),
    }),
    info: WorkspaceInfoSchema,
    resource_id: ResourceIdSchema,
    permission_list: z.array(PermissionSchema),
    role_list: z.array(
      z
        .object({
          role_id: z.string(),
          role_name: z.string(),
          role_level: z.number(),
          override_level: z.number(),
          is_system_created: z.number(),
        })
        .transform((prev) => ({
          roleId: prev.role_id,
          roleName: prev.role_name,
          roleLevel: prev.role_level,
          overrideLevel: prev.override_level,
          isSystemCreated: prev.is_system_created,
        }))
    ),
  })
  .transform((prev) => ({
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    description: prev.description,
    docType: 'workspace' as const,
    imageOrigUrl: prev.image_orig_url,
    imageUrl: prev.image_url,
    info: prev.info,
    permissionList: prev.permission_list,
    resourceId: prev.resource_id,
    roleList: prev.role_list,
    stringId: prev.string_id,
    subscription: prev.subscription,
    title: prev.title,
  }));

export type Workspace = z.infer<typeof WorkspaceSchema>;

export const GetWorkspaceListResponseSchema = z.object({
  records: WorkspaceSchema.array(),
  size: z.number(),
});

export type GetWorkspaceListResponse = z.infer<
  typeof GetWorkspaceListResponseSchema
>;

const BackendProjectSchema = z
  .object({
    created_at: z.string().transform((date) => new Date(date)),
    deleted_at: z.null(),
    description: z.string().nullable(),
    is_public: z.number().transform(Boolean),
    member_count: z.number(),
    permission_list: z.array(PermissionSchema),
    resource_id: ResourceIdSchema,
    string_id: ProjectIdSchema,
    title: z.string(),
  })
  // .strict()
  .transform((prev) => ({
    createdAt: prev.created_at,
    deletedAt: prev.deleted_at,
    description: prev.description,
    docType: 'project' as const,
    isPublic: prev.is_public,
    memberCount: prev.member_count,
    permissionList: prev.permission_list,
    resourceId: prev.resource_id,
    stringId: prev.string_id,
    title: prev.title,
  }));

export type Project = z.infer<typeof BackendProjectSchema> & {
  workspaceId: WorkspaceId;
};

export function ParseProject(
  value: unknown,
  workspaceId: WorkspaceId
): Project {
  return {
    ...BackendProjectSchema.parse(value),
    workspaceId,
  };
}

const GetWorkspaceProjectListResponseSchema = z.object({
  records: BackendProjectSchema.array(),
  size: z.number(),
});

export function ParseGetWorkspaceProjectListResponse(
  value: unknown,
  workspaceId: WorkspaceId
): Project[] {
  return GetWorkspaceProjectListResponseSchema.parse(value).records.map(
    (project) => ({
      ...project,
      workspaceId,
    })
  );
}

export const GetProjectMemberListResponseSchema = z.object({
  records: ProjectMemberSchema.array(),
  size: z.number(),
});

export type GetProjectMemberListResponse = z.infer<
  typeof GetProjectMemberListResponseSchema
>;

export const GetProjectInvitationListResponseSchema = z.object({
  records: ProjectInvitationSchema.array(),
  size: z.number(),
});

export type GetProjectInvitationListResponse = z.infer<
  typeof GetProjectInvitationListResponseSchema
>;

export const GetWorkspaceInvitationListResponseSchema = z.object({
  records: z.array(WorkspaceInvitationSchema),
  size: z.number(),
});

export type GetWorkspaceInvitationListResponse = z.infer<
  typeof GetWorkspaceInvitationListResponseSchema
>;

export const GetWorkspaceMemberListResponseSchema = z.object({
  records: WorkspaceMemberSchema.array(),
  size: z.number(),
});

export type GetWorkspaceMemberListResponse = z.infer<
  typeof GetWorkspaceMemberListResponseSchema
>;

export const defaultWorkspace: Workspace = {
  permissionList: [],
  docType: 'workspace',
  createdAt: new Date('2024-01-13T14:11:40.000Z'),
  description: 'This is a descriptionxx',
  deletedAt: null,
  imageOrigUrl: 'https://i.pravatar.cc/150?img=1',
  imageUrl: 'https://i.pravatar.cc/150?img=1',
  info: {
    seatsLimit: 10,
    seatsUsed: 4,
    storage: {
      durationRemainingMts: 0,
      durationSize: 0,
      durationSizeHrs: 0,
      segmentSize: '',
      segmentSizeGb: 0,
      segmentSizeLimit: 0,
    },
  },
  stringId: WorkspaceIdSchema.parse('SkKaQ1XVwj'),
  subscription: {
    enabled_feature_list: [],
    plan_description: '',
    plan_title: '',
    plan_is_free: false,
  },
  title: 'Workspace Name',
  resourceId: ResourceIdSchema.parse('w1'),
  roleList: [],
};

export const defaultProject: Project = {
  createdAt: new Date('2023-07-20T09:25:56.000Z'),
  deletedAt: null,
  description: 'This is a project',
  docType: 'project',
  isPublic: false,
  memberCount: 1,
  resourceId: ResourceIdSchema.parse('p1'),
  stringId: ProjectIdSchema.parse('project1'),
  title: 'Project 1',
  workspaceId: WorkspaceIdSchema.parse('workspace1'),
  permissionList: [],
};
