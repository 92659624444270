import { z } from 'zod';
import { WorkspaceSubscriptionEnabledFeatureSchema } from '../services/api/types/Workspace';

export const BillingPlanSchema = z.object({
  plan_id: z.string(),
  plan_name: z.string(),
  description: z.string(),
  features: z.array(
    z.object({
      description: z.string(),
    })
  ),
  enabled_feature_list: WorkspaceSubscriptionEnabledFeatureSchema.array(),
  // icon_url: z.string(),
  period: z.number(),
  period_unit: z.string(),
  // price: z.number(),
  // price_monthly: z.number(),
  currency_code: z.string(),
  storage_limit: z.number(),
  duration_limit: z.number(),
  tier_id: z.string(),
  // is_pro: z.boolean(),
});

export type BillingPlan = z.infer<typeof BillingPlanSchema>;

export const BillingPlanListResponseSchema = z.object({
  records: BillingPlanSchema.array(),
});
