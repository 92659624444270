import { RightPaneComponent } from '../reducers/types';

export function isSongPane(component: RightPaneComponent): boolean {
  return [
    RightPaneComponent.FILE_ATTACHMENTS,
    RightPaneComponent.FILE_COMMENTS,
    RightPaneComponent.FILE_INFO,
    RightPaneComponent.FILE_LYRICS,
  ].includes(component);
}

export function isWorkspacePane(component: RightPaneComponent): boolean {
  return [RightPaneComponent.WORKSPACE_INFO].includes(component);
}

export function isPlaylistPane(component: RightPaneComponent): boolean {
  return [
    RightPaneComponent.PLAYLIST_INFO,
    RightPaneComponent.PLAYLIST_ATTACHMENTS,
  ].includes(component);
}
